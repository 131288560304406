@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200&family=Ubuntu:ital,wght@1,300&display=swap');
*{
  font-family: 'Inter', sans-serif !important;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Inter', sans-serif !important;
    font-family: 'Ubuntu', sans-serif !important;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
    font-family: 'Inter', sans-serif !important;
    font-family: 'Ubuntu', sans-serif !important;
}


canvas {
  max-width: 80% !important;
  height: 600px !important;
  margin-left: 100px;
}

/* .swal-icon--success__hide-corners {
  height: 50px !important;
} */


/* .swal-icon--success:before,
.swal-icon--success:after {
} */
